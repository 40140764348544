
import { defineComponent } from 'vue'
import { getInsuranceOpenData, InsuranceOpenDto } from '@/api/insurance'
export default defineComponent({
    name: 'InsurancePlanComponent',
    props: ['nodata'],
    data () {
        return {
            data: {} as InsuranceOpenDto[]
        }
    },
    async created () {
        const result = await getInsuranceOpenData()
        this.data = result
        console.log(this.data)
    },
    methods: {}
})
